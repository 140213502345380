export default [
  {
    path: "/engagements",
    name: "engagements",
    component: () => import("@/views/engagement/Engagements.vue"),
  },
  {
    path: "/assignment-proposal",
    name: "assignment-proposal",
    component: () => import("@/views/engagement/AssignmentProposal.vue"),
  },
  {
    path: "/offer-accepted",
    name: "offer-accepted",
    component: () => import("@/views/engagement/OfferAccepted.vue"),
  },
  {
    path: "/timing-confirmation",
    name: "timing-confirmation",
    component: () => import("@/views/engagement/TimingConfirmation.vue"),
  },
  {
    path: "/interview-confirmation",
    name: "interview-confirmation",
    component: () => import("@/views/engagement/InterviewConfirmation.vue"),
  },
  {
    path: "/on-boarding",
    name: "on-boarding",
    component: () => import("@/views/engagement/OnBoarding.vue"),
  },
  {
    path: "/client-approval",
    name: "client-approval",
    component: () => import("@/views/engagement/ClientApproval.vue"),
  },
  {
    path: "/expert-payment",
    name: "expert-payment",
    component: () => import("@/views/engagement/ExpertPayment.vue"),
  },
  {
    path: "/add-assignment",
    name: "add-assignment",
    component: () => import("@/views/engagement/AddNewAssignment.vue"),
    meta: {
      pageTitle: "Add Assignments",
      breadcrumb: [
        {
          text: "Add Engagements",
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: "/edit-assignment/:id",
    name: "edit-assignment",
    component: () => import("@/views/engagement/EditAssignment.vue"),
    meta: {
      pageTitle: "Edit Assignments",
      breadcrumb: [
        {
          text: "Edit Engagements",
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: "/assignment-details/:id",
    name: "assignment-details",
    component: () => import("@/views/engagement/AssignmentDetails.vue"),
  },
]
