export default [
  {
    path: "/admin-engagement",
    name: "admin-engagement",
    component: () => import("@/views/admin-engagement/AdminEngagement.vue"),
    meta: {
      pageTitle: "Assignments",
      breadcrumb: [
        {
          text: "Engagements",
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: "/details-admin-engagement/:id",
    name: "details-admin-engagement",
    component: () =>
      import("@/views/admin-engagement/DetailsAdminEngagement.vue"),
    meta: {
      pageTitle: "Engagements",
      breadcrumb: [
        {
          text: "Engagements",
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: "/user-chat",
    name: "user-chat",
    component: () => import("@/views/chat/Chat.vue"),
    meta: {
      pageTitle: "Chat",
      breadcrumb: [
        {
          text: "Engagements",
          active: true,
        },
      ],
      authRequired: true,
    },
  },
]
