export default [
  {
    path: "/clients-list",
    name: "clients-list",
    component: () => import("@/views/clients/ClientList.vue"),
    meta: {
      pageTitle: "Clients",
      breadcrumb: [
        {
          text: "Clients",
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: "/add-client",
    name: "add-client",
    component: () => import("@/views/clients/AddClient.vue"),
    meta: {
      pageTitle: "Add Client",
      breadcrumb: [
        {
          text: "Clients",
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: "/client-details/:id",
    name: "client-details",
    component: () => import("@/views/clients/ClientDetails.vue"),
    meta: {
      pageTitle: "Client Details",
      breadcrumb: [
        {
          text: "Clients",
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: "/add-client-user/:id",
    name: "add-client-user",
    component: () => import("@/views/clients/AddClientUser.vue"),
    meta: {
      pageTitle: "Add Client User",
      breadcrumb: [
        {
          text: "Clients",
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: "/add-client-assignment/:id",
    name: "add-client-assignment",
    component: () => import("@/views/clients/AddClientAssignment.vue"),
    meta: {
      pageTitle: "Add Client Assignment",
      breadcrumb: [
        {
          text: "Clients",
          active: true,
        },
      ],
      authRequired: true,
    },
  },
]
