export default [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/auth/Login.vue"),
    meta: {
      layout: "full",
      redirectIfLoggedIn: true,
      authRequired: false,
    },
  },
  {
    path: "/register",
    name: "auth-register",
    component: () => import("@/views/auth/Register.vue"),
    meta: {
      layout: "full",
      redirectIfLoggedIn: true,
      authRequired: false,
    },
  },
]
