export default [
  {
    path: "/candidate-list",
    name: "candidate-list",
    component: () => import("@/views/candidate-list/CandidateList.vue"),
    meta: {
      pageTitle: "Candidates",
      breadcrumb: [
        {
          text: "Candidates",
          active: true,
        },
      ],
      authRequired: true,
    },
  },
]
